import "./index.css"

import { graphql } from "gatsby"
import React from "react"

import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout territory={"tech"}>
      <Seo title={t("Innovation")} />

      <PatternLateral territory="tech" />
      <h2 className="sectionTitle">
        <Trans>Innovation</Trans>
      </h2>

      <p className="mainText techBody">
        <Trans>Let's talk science</Trans>
      </p>
      <p className="mainText techBody">
        <Trans i18nKey="techTeam">
          We have a full fledged team of
          <h1 className="basic tech">sociologists</h1>
          specialized in
          <h1 className="basic ">innovating artist production environments</h1>
          for the digital industry, focusing on creating
          <h1 className="basic tech">integrity-based organizations</h1>
        </Trans>
      </p>
      <p className="mainText techBody">
        <Trans>Sounds good, right? It's even better.</Trans>
      </p>
      <p className="mainText techBody">
        <Trans i18nKey="techMethod">
          Our innovation department focuses on the study of
          <b> artists and their relation to the workplace</b>.
          <br />
          <br />
          Our method: a multidisciplinary approach which includes sociological
          understanding of organizations and scientific tools of behavior
          analysis.
        </Trans>
      </p>
      <p className="mainText techBody">
        <h2 className="basic tech">
          <Trans>From us, to everyone</Trans>
        </h2>
        <Trans i18nKey="techCollabs">
          La Tribu Animation is where the magic happens, but we strongly believe
          in{" "}
          <b>
            putting our knowledge beyond its borders to build better working
            environments everywhere
          </b>
          .
        </Trans>
        <br />
        <br />
        <Trans>
          This is why we collaborate and make workshops and organization
          consulting for other companies such as (among others) THU Trojan Horse
          Was a Unicorn, one of the major events of the digital industry; or the
          National Congress of Business Angels, the largest event for investors
          in Spain.
        </Trans>
      </p>
      <p className="mainText techBody">
        <Trans i18nKey="techComingSoon">
          We want to tell you about it in detail, with drawings, schemes and
          such... But we couldn't wait to launch our new website, so we'll do
          that in the future.
          <br />
          <br />
          See you back here in a couple of <s>weeks</s> months :)
        </Trans>
      </p>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
